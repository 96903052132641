@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-gray-50 text-black;
    }

    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply text-xl;
    }

    a {
        @apply text-brand-alt;
    }

    input[type='checkbox'], input[type='radio'] {
        @apply mr-4 h-8 w-8 text-brand-alt border-gray-300 rounded border-2;
    }

    input[type='checkbox'], input[type='radio'] {
        @apply cursor-pointer focus:ring-brand;
    }

        input[type='checkbox'] + label, input [type="radio"] + label {
            @apply cursor-pointer;
        }

        input[type='checkbox']:disabled, input[type='radio']:disabled,
        input[type='checkbox']:disabled + label, input[type='radio']:disabled + label {
            @apply cursor-default;
        }

    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply w-full max-w-lg block shadow-sm border-gray-600 placeholder-gray-400 focus:ring-brand-alt-light focus:border-brand-alt-light sm:text-sm rounded border-2;
    }

    ol, ul {
        margin: 0 20px;
        padding: 0 20px;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }
}

@layer components {
    .btn-dark {
        @apply py-2 px-4 cursor-pointer bg-brand text-white hover:text-white font-semibold rounded-lg shadow-md hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-opacity-75;
    }

        .btn-dark:disabled {
            @apply opacity-50 bg-brand hover:bg-brand cursor-default;
        }

    .btn {
        @apply py-2 px-4 cursor-pointer bg-brand-alt text-white hover:text-white font-semibold rounded-lg shadow-md hover:bg-brand-alt-dark focus:outline-none focus:ring-2 focus:ring-brand-alt focus:ring-opacity-75;
    }

        .btn:disabled {
            @apply opacity-50 bg-brand-alt hover:bg-brand-alt cursor-default;
        }

    .btn-gray {
        @apply py-2 px-4 cursor-pointer bg-gray-600 text-white hover:text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-75;
    }

    .btn-red {
        @apply py-2 px-4 cursor-pointer bg-red-600 text-white hover:text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-100 focus:ring-opacity-75;
    }

    .btn-green {
        @apply py-2 px-4 cursor-pointer bg-green-600 text-white hover:text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-100 focus:ring-opacity-75;
    }

        .btn:disabled, .btn-dark:disabled, .btn-gray:disabled, .btn-red:disabled, .btn-green:disabled {
            @apply opacity-50 bg-gray-300 hover:bg-gray-300 cursor-default;
        }
}